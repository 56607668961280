import clsx from 'clsx';
import { FC } from 'react';

import { Icons } from 'src/assets/icons';
import { ActivityHealth, ActivityTrack, ActivityUpdated, WorkTrack } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';

import { getWorkTrackValue } from '../../helpers';

interface ActivityBadgeProps {
  status: ActivityHealth | ActivityTrack | ActivityUpdated;
  onValueSelect: (status: ActivityHealth | ActivityTrack | ActivityUpdated) => void;
  updatesCount?: number;
  isBadge?: boolean;
}

const ActivityBadge: FC<ActivityBadgeProps> = ({
  status,
  onValueSelect,
  updatesCount,
  isBadge,
}) => {
  const title = getWorkTrackValue(status);

  return (
    <button
      type="button"
      onClick={() => onValueSelect(status)}
      disabled={isBadge}
      className={clsx('flex items-center gap-1 px-2 py-0.5 rounded-[25px]', {
        'bg-[#CCFBE1]': status === 'OnTrack' || status === 'Completed',
        'bg-[#FFC2C2]': status === 'Delayed',
        'bg-[#F6FCB1]': status === 'NotStarted',
        'bg-bgColor-blue': status === 'InProgress',
        'bg-[#EEEEEE]': status === 'OnHold',
        'bg-[#FAE4C2]': status === 'Updated',
      })}
    >
      <Typography
        variant="c2"
        className={clsx('text-xs leading-[18px]', {
          'text-[#14B78B]': status === 'OnTrack' || status === 'Completed',
          'text-textColor-danger': status === 'Delayed',
          'text-[#93B700]': status === 'NotStarted',
          'text-semanticColor-info': status === 'InProgress',
          'text-[#898989]': status === 'OnHold',
          'text-semanticColor-warning': status === 'Updated',
        })}
      >
        {title}
      </Typography>

      {status === 'OnTrack' && (
        <Icons.Outlined.Edit.TargetIcon className="fill-[#14B78B] w-4 h-4" />
      )}

      {status === 'Delayed' && (
        <Icons.Outlined.Notifications.AlertTriangleIcon className="fill-textColor-danger w-4 h-4" />
      )}

      {status === 'NotStarted' && (
        <Icons.Outlined.Edit.EditIcon className="fill-[#93B700] w-4 h-4" />
      )}

      {status === 'InProgress' && (
        <Icons.Outlined.Edit.EditIcon className="fill-semanticColor-info w-4 h-4" />
      )}

      {status === 'OnHold' && (
        <Icons.Outlined.Misc.ClockAlertIcon className="fill-[#898989] w-4 h-4" />
      )}

      {status === 'Updated' && (
        <div className="w-4 h-4 rounded-full bg-white flex items-center justify-center">
          <Typography
            variant="c2"
            className="text-[10px] leading-3 text-center"
          >
            {updatesCount}
          </Typography>
        </div>
      )}
    </button>
  );
};

export { ActivityBadge };
