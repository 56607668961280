import { FC, Dispatch, SetStateAction, useMemo, useState, ChangeEvent, useEffect } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useSelector } from 'react-redux';

import { Button } from 'src/shared/ui/button';
import { Icons } from 'src/assets/icons';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { TextField } from 'src/shared/ui/textField';
import { dayjs, not, showToastErrorMessage } from 'src/shared/utils';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { ActivityJob, ActivityTrack, WorkTrack } from 'src/shared/types';
import {
  useGetDelayCategoriesQuery,
  useGetFreeByDatesProviderTeamsQuery,
  usePostActivityStateMutation,
} from 'src/store/api/activities';
import { selectCurrentUser } from 'src/store/slices';
import duration from 'dayjs/plugin/duration';

import { TextAreaInput } from '../textAreaInput';
import { ACTIVITY_STATUSES, addUpdateSchema, getWorkTrackValue } from '../../helpers';
import { DueDateRangePicker } from '../dueDateRangePicker';
import { Checkbox } from '@mui/material';
import { MuiDateTimePicker } from 'src/shared/ui/muiDateTimePicker';

dayjs.extend(duration);
interface AddUpdateModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedJob: ActivityJob;
  timeZone: string;
}

const AddUpdateModal: FC<AddUpdateModalProps> = ({ isOpen, setIsOpen, selectedJob, timeZone }) => {
  const [postActivityState, { isLoading: isLoadingNewState }] = usePostActivityStateMutation();

  const { data: providerTeamValues } = useGetFreeByDatesProviderTeamsQuery(
    {
      id: selectedJob.providerId,
      startDate: dayjs.utc(selectedJob.startWork).tz(timeZone).toISOString(),
      endDate: dayjs.utc(selectedJob.endWork).tz(timeZone).toISOString(),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: delayCategories } = useGetDelayCategoriesQuery();

  const delayCategoriesVariants = useMemo(() => {
    const emptyState = {
      id: '',
      name: '',
    };

    const preparedDelayCategories = delayCategories
      ? delayCategories.map(({ DelayCategory, DelayCategoryID }) => ({
          name: DelayCategory,
          id: DelayCategoryID,
        }))
      : [];

    return [emptyState, ...preparedDelayCategories];
  }, [delayCategories]);

  const initialValues = useMemo(() => {
    return {
      notes: '',
      delayCategory: {
        id: '',
        name: '',
      },
      percentComplete: `${selectedJob.workProgress}`,
      activityStatus: selectedJob.workStatus,
      startTime: dayjs.utc(selectedJob.startWork).tz(timeZone).toISOString(),
      endTime: dayjs.utc(selectedJob.startWork).add(1, 'hour').tz(timeZone).toISOString(),
      duration: '',
      providerTeam: {
        id: selectedJob.providerTeamId,
        name: selectedJob.providerTeam.name,
      },
      completedDate: dayjs.utc().tz(timeZone).toISOString(),
      isCompleted: false,
    };
  }, [selectedJob, dayjs, timeZone]);

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const user = useSelector(selectCurrentUser);

  const onSubmit = async (data: z.infer<typeof addUpdateSchema>) => {
    if (!user) {
      return;
    }
    const isInProgress = data.activityStatus === 'InProgress';

    const originalStart = dayjs.utc(selectedJob.actualStart);
    const originalEnd = dayjs.utc(selectedJob.actualEnd);
    const originalDuration = dayjs.duration(originalEnd.diff(originalStart));

    const startDate = dayjs.utc(isInProgress ? data.startTime : selectedJob.startWork).tz(timeZone);
    let endDate = dayjs
      .utc(data.isCompleted ? data.completedDate : selectedJob.endWork)
      .tz(timeZone);

    const isEarlyStart = isInProgress && startDate.isBefore(originalStart);
    const isDelayedSelected = !!data.delayCategory?.id;

    if (isEarlyStart) {
      endDate = startDate.add(originalDuration);
    }

    try {
      await postActivityState({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        notes: data.notes,
        delayCategoryID: data.delayCategory.id,
        name: selectedJob.activityName,
        equipment: selectedJob.equipment,
        equipmentTypeId: selectedJob.equipmentTypeId,
        progress: Number(data.percentComplete),
        providerTeamId: data.providerTeam.id,
        providerId: selectedJob.providerId,
        activityId: selectedJob.id,
        health: selectedJob.workTrack,
        track: data.activityStatus as ActivityTrack,
        jobNumber: selectedJob.jobNumber,
        reportedBy: user.email,
        delayStart: isDelayedSelected ? data.startTime : undefined,
        delayEnd: isDelayedSelected ? data.endTime : undefined,
      }).unwrap();
      closeModal();
    } catch (error) {
      showToastErrorMessage('There was an error trying to update activity');
    }
  };

  const { values, handleChange, handleSubmit, setFieldValue, errors, dirty } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(addUpdateSchema),
    initialValues,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (values.activityStatus === 'Completed') {
      setFieldValue('isCompleted', true);
      setFieldValue('percentComplete', '100');
    }
  }, [values.activityStatus]);

  const activityStatusItems = useMemo(
    () =>
      ACTIVITY_STATUSES.map((option) => ({
        label: (
          <SelectInputItem selected={values.activityStatus === option}>
            {getWorkTrackValue(option)}
          </SelectInputItem>
        ),
        value: option,
        onClick: () => {
          handleChange({
            target: {
              name: 'activityStatus',
              value: option,
            },
          });
        },
      })),
    [values.activityStatus, handleChange],
  );

  const providerTeamVariants = useMemo(() => {
    const initialProviderTeam = {
      name: selectedJob.providerTeam.name,
      id: selectedJob.providerTeamId,
    };

    return providerTeamValues
      ? [initialProviderTeam, ...providerTeamValues]
      : [initialProviderTeam];
  }, [providerTeamValues, selectedJob]);

  const providerTeamItems = useMemo(
    () =>
      providerTeamVariants.map(({ id, name }) => ({
        label: (
          <SelectInputItem selected={values.providerTeam.name === name}>{name}</SelectInputItem>
        ),
        labelName: name,
        value: id,
        onClick: () => {
          handleChange({
            target: {
              name: 'providerTeam',
              value: {
                id,
                name,
              },
            },
          });
        },
      })),
    [providerTeamVariants, values.providerTeam, handleChange],
  );

  const delayCategoriesItems = useMemo(
    () =>
      delayCategoriesVariants?.map(({ name, id }) => ({
        label: <SelectInputItem selected={values.delayCategory.id === id}>{name}</SelectInputItem>,
        value: id,
        onClick: () => {
          handleChange({
            target: {
              name: 'delayCategory',
              value: {
                id,
                name,
              },
            },
          });
        },
      })),
    [delayCategoriesVariants, values.delayCategory, handleChange],
  );

  const handleProgressChange = (value: ChangeEvent<HTMLInputElement>) => {
    const inputValue = value.target.value;
    setFieldValue('isCompleted', Number(inputValue) === 100);

    handleChange({
      target: {
        name: 'percentComplete',
        value: value.target.value,
      },
    });
  };

  const handleCompletedDateChange = (newValue: string | null) => {
    setFieldValue('completedDate', newValue);
  };

  const handleCompletedChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFieldValue('isCompleted', isChecked);
    setFieldValue('percentComplete', isChecked ? '100' : `${selectedJob.workProgress}`);
  };

  const handleActualStartChange = (newDate: string | null) => {
    setFieldValue('startTime', newDate || '');
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[calc(100%-30px)] md:w-[646px]"
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        Add Update
      </Typography>

      <form
        className="flex flex-col justify-between gap-y-6 min-h-[368px] w-full"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <div
            className={clsx(
              'flex flex-col gap-y-8 w-full max-h-[60vh] lg:max-h-[50vh] overflow-y-scroll pr-2',
            )}
          >
            <SelectInput
              isRequired
              required
              name="activityStatus"
              label="Activity Status"
              value={getWorkTrackValue(values.activityStatus)}
              placeholder="Select status..."
              items={activityStatusItems}
            />

            <SelectInput
              isRequired
              required
              name="providerTeam"
              label="Assign team"
              value={values.providerTeam.name}
              placeholder={selectedJob.providerTeam.name}
              disabled={!providerTeamValues}
              items={providerTeamItems}
            />
            {values.activityStatus === 'InProgress' && (
              <MuiDateTimePicker
                label="Actual Start Time"
                value={values.startTime}
                onChange={handleActualStartChange}
                ampm={false}
              />
            )}
            <TextAreaInput
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'notes',
                    value,
                  },
                })
              }
              value={values.notes}
              label="Update Notes"
              placeholder="Add update notes"
              textLabelClassName="text-textColor-secondary font-semibold"
            />

            <div className="md:flex-row flex-col flex gap-8">
              <SelectInput
                name="delayCategory"
                label="Delay Type"
                value={values.delayCategory.name || ''}
                placeholder="Specify delay type"
                disabled={!delayCategories}
                onClear={() =>
                  setFieldValue('delayCategory', {
                    id: '',
                    name: '',
                  })
                }
                items={delayCategoriesItems}
              />

              <TextField
                name="percentComplete"
                label="Estimated Percent Complete"
                placeholder="Specify estimated percent complete rate"
                type="number"
                value={values.percentComplete}
                disabled={selectedJob.workStatus === WorkTrack.Completed}
                onChange={handleProgressChange}
                error={errors.percentComplete}
              />
            </div>

            {values.delayCategory.id && (
              <DueDateRangePicker
                setFieldValue={setFieldValue}
                values={values}
                timeZone={timeZone}
                labels={{
                  startTime: 'Delay Start',
                  duration: 'Delay Duration',
                  endTime: 'Delay End',
                }}
                errors={errors}
                ampm={false}
              />
            )}
            <div>
              <Typography
                variant="label"
                className="mb-2"
              >
                Complete
              </Typography>
              <div className="w-full lg:w-2/3 flex">
                <Checkbox
                  checked={values.isCompleted}
                  onChange={handleCompletedChange}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                />
                <MuiDateTimePicker
                  value={values.completedDate}
                  onChange={handleCompletedDateChange}
                  error={errors.completedDate}
                  ampm={false}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
            <Button
              type="button"
              variant="outlined"
              color="basic"
              size="lg"
              onClick={closeModal}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              color="primary"
              size="lg"
              endIcon={<Icons.Filled.Chevrons.ChevronRight className="fill-white" />}
              autoFocus
              disabled={!dirty || isLoadingNewState}
            >
              Add update
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { AddUpdateModal };
