import { ChangeEvent, Dispatch, FC, SetStateAction, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useSelector } from 'react-redux';

import { Button } from 'src/shared/ui/button';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { not } from 'src/shared/utils';
import { Icons } from 'src/assets/icons';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { useGetActivityProvidersQuery, useGetUploadJobsPathQuery } from 'src/store/api/activities';
import { selectCurrentUser } from 'src/store/slices';
import { getTenant } from 'src/shared/utils/tenant';
import { usePostUploadNewJobs } from 'src/shared/hooks/usePostUploadNewJobs';

import { DEFAULT_SELECT_VALUE, isValidFile, uploadJobsSchema, XLSX_TYPE } from '../../helpers';

interface UploadJobsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const UploadJobsModal: FC<UploadJobsModalProps> = ({ isOpen, setIsOpen }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { tenant } = getTenant();

  const { isLoadingNewJobs, newJobsResponse, postNewJobs } = usePostUploadNewJobs();

  const { data: uploadJobsPathData } = useGetUploadJobsPathQuery(
    {
      tenant: tenant || '',
    },
    {
      skip: !tenant,
    },
  );

  const user = useSelector(selectCurrentUser);

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const { data: providerValues } = useGetActivityProvidersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const providerVariants = useMemo(() => {
    return (
      providerValues?.map((provider) => ({
        id: provider.ProviderID,
        name: provider.ProviderName,
      })) || [DEFAULT_SELECT_VALUE]
    );
  }, [providerValues]);

  const onSubmit = async (data: z.infer<typeof uploadJobsSchema>) => {
    if (!selectedFile || !user) {
      return;
    }
    const location = window.location.origin;

    const formJsonData = {
      origin: location,
      userId: user.email,
      provider_id: data.provider.id,
    };

    const formData = new FormData();

    formData.append('file', selectedFile, selectedFile.name);
    formData.append('json_data', JSON.stringify(formJsonData));

    await postNewJobs({
      formData,
      pathname: uploadJobsPathData?.ta_fileprocessor_endpoint || '',
      key: uploadJobsPathData?.ta_fileprocessor_api_key || '',
    });
  };

  const initialValues = {
    provider: DEFAULT_SELECT_VALUE,
    fileName: '',
  };

  const { values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(uploadJobsSchema),
    initialValues,
    enableReinitialize: true,
  });

  const closeModal = () => {
    setSelectedFile(null);
    resetForm();
    setIsOpen(false);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files?.length) {
      return;
    }

    const inputFile = event.target.files[0];

    const isValidInputFile = isValidFile(inputFile);

    if (isValidInputFile) {
      setSelectedFile(inputFile);
      setFieldValue('fileName', inputFile.name);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[calc(100%-30px)] md:w-[646px] overflow-y-auto"
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        Upload Activities
      </Typography>

      {newJobsResponse ? (
        <div className="flex flex-col items-center justify-center gap-y-6 w-full max-h-[60vh] lg:max-h-[50vh] overflow-y-auto p-1 my-auto">
          <Typography
            variant="h2"
            className="text-green-900 text-center"
          >
            {newJobsResponse}
          </Typography>

          <Button
            type="button"
            variant="filled"
            color="success"
            size="lg"
            onClick={closeModal}
            className="w-max mx-auto"
          >
            Okay
          </Button>
        </div>
      ) : (
        <form
          className="w-full"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-y-6 w-full max-h-[60vh] lg:max-h-[50vh] min-h-[368px] md:h-[616px] overflow-y-auto p-1">
            <SelectInput
              isRequired
              required
              name="provider"
              label="Assign Provider"
              value={values.provider.name}
              placeholder="Assign provider ..."
              disabled={!providerVariants}
              onClear={() => setFieldValue('provider', DEFAULT_SELECT_VALUE)}
              items={
                providerVariants.map(({ id, name }) => ({
                  label: (
                    <SelectInputItem selected={values.provider.name === name}>
                      {name}
                    </SelectInputItem>
                  ),
                  value: id,
                  onClick: () => {
                    handleChange({
                      target: {
                        name: 'provider',
                        value: {
                          id,
                          name,
                        },
                      },
                    });
                  },
                })) ?? []
              }
            />

            <div className="flex gap-2 items-center justify-start">
              <input
                id="downloadFile"
                type="file"
                accept={`.xlsx, ${XLSX_TYPE}`}
                onChange={handleFileChange}
                ref={inputRef}
                className="hidden"
              />

              <Button
                variant="filled"
                onClick={handleInputClick}
                className="w-[140px] bg-brandingColor-primary-gradient text-white"
                endIcon={<Icons.Filled.Files.UploadIcon />}
                iconClassName="fill-white"
              >
                Upload
              </Button>

              <div>{values.fileName}</div>
            </div>
          </div>

          <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
            <Button
              type="button"
              variant="outlined"
              color="basic"
              size="lg"
              onClick={closeModal}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              color="primary"
              size="lg"
              endIcon={<Icons.Outlined.Edit.CheckmarkIcon className="fill-white" />}
              autoFocus
              disabled={isLoadingNewJobs}
            >
              Upload
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export { UploadJobsModal };
