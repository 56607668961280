import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';
import { SurveyModel } from 'survey-core';
import { useCallback, useMemo, useState } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { useGetSurveyAnswersByFormIdQuery, useGetSurveyContentQuery } from 'src/store/api';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { BackButton } from 'src/shared/ui/backButton';
import {
  useDeleteSurveyAnswerMutation,
  useLazyGetSurveyAnswersForExportByFormIdQuery,
} from 'src/store/api/surveyAnswer';
import { Spinner } from 'src/shared/ui/spinner';
import { SurveyAnswerEntity, PageSize, SortBy, SortOrder } from 'src/shared/types';
import { Pagination } from 'src/shared/ui/pagination';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { PAGE_SIZE_OPTIONS } from 'src/shared/constants/pagination';
import { Button } from 'src/shared/ui/button';

import { DeleteSurveyAnswerModal } from './Features/DeleteSurveyAnswerModal';
import {
  SURVEY_ANSWERS_DOWNLOADER_CONTAINER_ID,
  VisualizationPanel,
} from './Features/VisualizationPanel';
import { SearchField } from './Features/SearchField';
import { getAdaptedTabulatorAnswers } from './adapters';

import './style.css';

const SEARCH_FIELD_DEBOUNCE_DELAY = 500;

const SurveyAnswers = () => {
  const { formId } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const getPageSize = (size: string | null): PageSize => {
    const parsed = parseInt(size || '5', 10);

    if (PAGE_SIZE_OPTIONS.includes(parsed as PageSize)) {
      return parsed as PageSize;
    }

    return 5 as PageSize;
  };

  const [pageSize, setPageSize] = useState<PageSize>(getPageSize(searchParams.get('pageSize')));
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1', 10));
  const [searchField, setSearchField] = useState<string>(searchParams.get('search') || '');
  const [survey, setSurvey] = useState<SurveyModel | null>(null);
  const [sort, setSort] = useState<SortBy>({
    fieldKey: 'createdAt',
    order: SortOrder.DESC,
  });

  const debouncedSearchField = useDebounce(searchField, SEARCH_FIELD_DEBOUNCE_DELAY);

  const { data: form, isFetching: isLoadingForm } = useGetSurveyContentQuery(formId || '', {
    refetchOnMountOrArgChange: true,
  });

  const pathData = useMemo(() => {
    if (form && Array.isArray(form?.content?.pages)) {
      const elements: string[] = [];
      form.content?.pages.forEach((page: object) => {
        if ('elements' in page && Array.isArray(page.elements)) {
          page.elements.forEach((el) => {
            if (el?.name) {
              elements.push(el.name);
            }
          });
        }
      });
      return elements;
    }
    return [];
  }, [form]);

  const {
    data = {
      data: [],
      total: 0,
      totalPages: 0,
    },
    isFetching: isFetchingAnswers,
  } = useGetSurveyAnswersByFormIdQuery(
    {
      formId: formId ?? '',

      pagination: {
        pageSize: searchParams.get('pageSize') || '5',
        page: searchParams.get('page') || '1',
      },
      filters: {
        searchField: debouncedSearchField.trim(),
        path: pathData,
      },
      sort: {
        fieldKey: sort.fieldKey,
        order: sort.order,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !formId,
    },
  );

  const [fetchSurveyAnswersForExportLazy, { isFetching: isFetchingSurveyAnswersForExportLazy }] =
    useLazyGetSurveyAnswersForExportByFormIdQuery();

  const { data: answers, total } = data;

  const [deleteAnswer, { isLoading: isDeletingAnswer }] = useDeleteSurveyAnswerMutation();

  const [isDeleteSurveyAnswerModalOpen, setIsDeleteSurveyAnswerModalOpen] = useState(false);
  const [selectedSurveyAnswer, setSelectedSurveyAnswer] = useState<SurveyAnswerEntity | undefined>(
    undefined,
  );

  const handleNavigationClick = (href: string) => {
    navigate(href);
  };

  const handleSearchFieldChange = (searchFieldValue: string) => {
    setSearchField(searchFieldValue);
    setPage(1);

    if (!searchFieldValue) {
      const newSearchParams = new URLSearchParams(searchParams.toString());

      newSearchParams.delete('search');

      setSearchParams(newSearchParams);

      return;
    }
    if (searchFieldValue) {
      setSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        search: searchFieldValue,
      });
    }
  };

  const handlePageChange = (pageValue: number) => {
    setPage(pageValue);
    setSearchParams({
      page: pageValue.toString(),
      pageSize: pageSize.toString(),
      ...(searchField && { search: searchField }),
    });
  };

  const handlePageSizeChange = (pageSizeValue: PageSize) => {
    setPageSize(pageSizeValue);
    setPage(1);
    setSearchParams({
      page: String(1),
      pageSize: pageSizeValue.toString(),
      ...(searchField && { search: searchField }),
    });
  };

  const handleDownloadClick = async () => {
    if (!survey) {
      showToastErrorMessage('Survey in not initialized');

      return;
    }

    try {
      const { data: allAnswers } = await fetchSurveyAnswersForExportLazy({
        formId: formId || '',
      }).unwrap();

      const downloader = new Tabulator(survey, getAdaptedTabulatorAnswers(allAnswers), {
        downloadOptions: {
          fileName: 'results',
        },
      });
      downloader.render(SURVEY_ANSWERS_DOWNLOADER_CONTAINER_ID);
      downloader.download('csv');
      downloader.destroy();
    } catch {
      showToastErrorMessage('Failed to download the survey answers');
    }
  };

  const handleSortChange = useCallback(
    ({ fieldKey, order }: SortBy) => {
      if (fieldKey !== sort.fieldKey || order !== sort.order) {
        setSort({
          fieldKey,
          order,
        });
      }
    },
    [sort, setSort],
  );

  if (not(form) && not(isLoadingForm)) {
    showToastErrorMessage('There was an error trying to load the form');

    navigate('/forms');

    return null;
  }

  return (
    <>
      {isLoadingForm && (
        <Spinner
          withBackdrop
          fallbackText="Loading form answers..."
        />
      )}

      {isDeletingAnswer && (
        <Spinner
          withBackdrop
          fallbackText="Deleting form..."
        />
      )}

      <div className="flex flex-col w-full gap-y-4 py-4 px-4">
        <div className="flex items-center gap-4 flex-wrap">
          <BackButton
            href="/forms"
            handleNavigationClick={handleNavigationClick}
          />

          <Typography variant="h1">Survey Answers</Typography>
        </div>

        <div className="w-full p-2 grid grid-cols-1 lg:grid-cols-2-2-1 gap-4 bg-white rounded-xl">
          <div className="w-full min-w-[300px]">
            <SearchField
              value={searchField}
              handleChange={handleSearchFieldChange}
              isFetching={isFetchingAnswers}
            />
          </div>

          <div className="w-full">
            <Pagination
              pagination={{
                currentPage: page,
                onPageChange: handlePageChange,
                onPageSizeChange: handlePageSizeChange,
                pageSize,
                total,
              }}
              className="rounded-xl"
            />
          </div>

          <Button
            className="w-full h-full"
            variant="filled"
            color="primary"
            onClick={handleDownloadClick}
            disabled={isFetchingSurveyAnswersForExportLazy}
          >
            Download CSV
          </Button>
        </div>

        <VisualizationPanel
          form={form}
          answers={answers}
          deleteAnswer={deleteAnswer}
          setIsDeleteSurveyAnswerModalOpen={setIsDeleteSurveyAnswerModalOpen}
          setSelectedSurveyAnswer={setSelectedSurveyAnswer}
          onSurveyInitialized={setSurvey}
          handleSortChange={handleSortChange}
        />
      </div>

      <DeleteSurveyAnswerModal
        isOpen={isDeleteSurveyAnswerModalOpen}
        setIsOpen={setIsDeleteSurveyAnswerModalOpen}
        answer={selectedSurveyAnswer}
      />
    </>
  );
};

export { SurveyAnswers };
