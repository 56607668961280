import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { ActivityJob } from 'src/shared/types';
import { calculateActivityDurationPercentage } from '../../helpers';

interface CardColorIndicatorProps {
  card: ActivityJob;
}

const CardColorIndicator: React.FC<CardColorIndicatorProps> = ({ card }) => {
  const { workProgress, startWork, endWork, workStatus, updates } = card;

  const indicatorColor = useMemo(() => {
    const calculatedProgress = calculateActivityDurationPercentage({ endWork, startWork });
    const currentTime = dayjs();
    const startTime = dayjs(startWork);
    const endTime = dayjs(endWork);
    const isCompleted = workProgress === 100 || workStatus === 'Completed';
    const isScheduled = currentTime.isBefore(startTime) && workStatus !== 'InProgress';
    const isOnTrack = currentTime.isBetween(startTime, endTime) || workStatus === 'InProgress';
    const isDelayed =
      updates?.some((item) => item.delayCategoryName) && workProgress >= calculatedProgress;
    const isOffTrack = workProgress < calculatedProgress;

    if (isCompleted) {
      return 'bg-black';
    }
    if (isScheduled) {
      return 'bg-gray-500';
    }
    if (isDelayed) {
      return 'bg-semanticColor-warning';
    }
    if (isOffTrack) {
      return 'bg-semanticColor-danger';
    }
    if (isOnTrack) {
      return 'bg-[#14B78B]';
    }

    return 'bg-gray-300';
  }, [workProgress, startWork, endWork, workStatus, updates]);

  return <div className={clsx('w-2.5 rounded-l-lg shrink-0', indicatorColor)} />;
};

export { CardColorIndicator };
