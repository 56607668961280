import { Dispatch, FC, FormEvent, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { not, showToastErrorMessage } from 'src/shared/utils';
import {
  TypeNotation,
  TagType,
  TableManagerFieldValue,
  RelationOptionsMap,
  MetadataMap,
  DefaultTableValue,
  AdminPageContextType,
} from 'src/shared/types';
import { selectCurrentUser, adminTableActions, selectCreateModalState } from 'src/store/slices';
import { useCreateTableRowMutation, useEditTableRowMutation } from 'src/store/api';
import { Button } from 'src/shared/ui/button';
import { Spinner } from 'src/shared/ui/spinner';
import { BodyModal, ContainerModal, HeaderModal, FooterModal } from 'src/shared/ui/modal/ui';
import { useAppDispatch } from 'src/store';

import { FormFields } from './FormFields';
import {
  prepareDateValue,
  prepareMultiselectValue,
  prepareOptionValue,
  checkForChanges,
} from './helpers';

type InitialState = {
  [key: string]: TableManagerFieldValue;
};

type CreateOrUpdateTableRowModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  schema: TypeNotation[];
  idField: string;
  modelName: TagType;
  relationOptions: RelationOptionsMap;
  fieldsMetadata: MetadataMap;
  selectedRow: Record<string, TableManagerFieldValue> | null;
  setSelectedRow: Dispatch<SetStateAction<Record<string, TableManagerFieldValue> | null>>;
};

const CREATE_ENTITY_FORM = 'createEntityForm';

const CreateOrUpdateTableRowModal: FC<CreateOrUpdateTableRowModalProps> = ({
  schema,
  idField,
  isOpen,
  modelName,
  relationOptions,
  fieldsMetadata,
  selectedRow,
  setIsOpen,
  setSelectedRow,
}) => {
  const dispatch = useAppDispatch();
  const defaultCreateModalState = useSelector(selectCreateModalState);

  const { modelsOptions } = useOutletContext<AdminPageContextType>();
  const user = useSelector(selectCurrentUser);
  const [createTableRow] = useCreateTableRowMutation();
  const [editTableRow] = useEditTableRowMutation();
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const isEditMode = !!selectedRow;

  const getInitialState = (): InitialState => {
    const initialState: InitialState = {};

    const defaultValues: Record<string, TableManagerFieldValue> = {
      boolean: false,
      string: '',
      number: '',
      date: {
        startDate: '',
        endDate: '',
      },
      relationOption: {
        value: '',
        label: '',
      },
      relationOptions: [],
      array: [],
      enum: '',
    };

    if (!isEditMode) {
      schema.forEach((item) => {
        if (
          fieldsMetadata?.create?.[item.field]?.hidden &&
          fieldsMetadata?.create?.[item.field]?.defaultValue === DefaultTableValue.email &&
          user?.email
        ) {
          initialState[item.field] = user.email;
        } else if (
          fieldsMetadata?.create?.[item.field]?.defaultValue ===
            DefaultTableValue.ownerLocationId &&
          user?.ProviderRoleMatrix.OwnerLocationID
        ) {
          initialState[item.field] =
            relationOptions[item.field].find(({ value }) => {
              return value === user.ProviderRoleMatrix.OwnerLocationID;
            }) || '';
        } else if (
          item.field in relationOptions &&
          fieldsMetadata?.multiSelectFields?.includes(item.field)
        ) {
          initialState[item.field] = defaultValues.relationOptions;
        } else if (
          item.field in relationOptions &&
          defaultCreateModalState &&
          item.field in defaultCreateModalState &&
          defaultCreateModalState[item.field].field === item.field
        ) {
          const currentOption = relationOptions[item.field].find(
            (el) => el.value === defaultCreateModalState[item.field].value,
          );

          initialState[item.field] = currentOption ?? defaultValues.relationOption;
        } else if (item.field in relationOptions) {
          initialState[item.field] = defaultValues.relationOption;
        } else if ('defaultValue' in item && item.defaultValue !== undefined) {
          initialState[item.field] = item?.defaultValue;
        } else {
          initialState[item.field] = defaultValues[item.type];
        }
      });

      return initialState;
    }
    schema.forEach((item) => {
      if (
        fieldsMetadata?.update?.[item.field]?.hidden &&
        fieldsMetadata?.update?.[item.field]?.defaultValue === DefaultTableValue.email &&
        !fieldsMetadata?.update?.[item.field]?.readonly &&
        user?.email
      ) {
        initialState[item.field] = user.email;
      } else if (
        item.field in relationOptions &&
        fieldsMetadata?.multiSelectFields?.includes(item.field)
      ) {
        const currentValues = selectedRow[item.field] as string[];
        const currentOptions = Array.isArray(currentValues)
          ? relationOptions[item.field].filter((el) => currentValues.includes(el.value))
          : [];
        initialState[item.field] = currentOptions.length > 0 ? currentOptions : [];
      } else if (item.field in relationOptions) {
        const currentValue = selectedRow[item.field];
        const currentOption = relationOptions[item.field].find((el) => el.value === currentValue);
        initialState[item.field] = currentOption ?? defaultValues.relationOption;
      } else if (item.type === 'date') {
        initialState[item.field] = {
          startDate: (selectedRow[item.field] as string) ?? '',
          endDate: (selectedRow[item.field] as string) ?? '',
        };
      } else {
        initialState[item.field] = selectedRow[item.field];
      }
    });
    return initialState;
  };

  const [formState, setFormState] = useState(getInitialState);

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
    if (isOpen) {
      setFormState(getInitialState);
      setSelectedRow(null);
      dispatch(adminTableActions.selectRow({ id: null, typeModal: null }));
      dispatch(adminTableActions.updateCreateModalValues(null));
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedRow(null);
    setFormState(getInitialState);
    dispatch(adminTableActions.selectRow({ id: null, typeModal: null }));
    dispatch(adminTableActions.updateCreateModalValues(null));
  };

  const handleChange = (field: string, value: TableManagerFieldValue) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const preparedFormState = Object.fromEntries(
        Object.entries(formState).map(([key, value]) => {
          const preparedDate = prepareDateValue(value, key, fieldsMetadata);
          if (preparedDate !== null) {
            return preparedDate;
          }

          const preparedMultiselect = prepareMultiselectValue(value, key);
          if (preparedMultiselect !== null) {
            return preparedMultiselect;
          }

          const preparedOption = prepareOptionValue(value, key, selectedRow);
          if (preparedOption !== null) {
            return preparedOption;
          }

          if (value === '' && (!selectedRow || selectedRow[key] === value)) {
            return [key, undefined];
          }
          if (value === null) {
            return [key, undefined];
          }

          const currentEL = schema.find((el) => el.field === key);
          const currentValue = currentEL?.type === 'number' ? Number(value) : value;

          return [key, currentValue];
        }),
      );

      setIsLoadingForm(true);

      const updatedFormState = checkForChanges(preparedFormState, selectedRow);

      if (isEditMode && selectedRow) {
        await editTableRow({
          id: String(selectedRow[idField]),
          apiEndpoint: modelsOptions?.[modelName].apiEndpoint,
          modelName,
          body: updatedFormState,
          invalidatesTags: fieldsMetadata?.invalidatesTags,
        }).unwrap();
      } else {
        await createTableRow({
          apiEndpoint: modelsOptions?.[modelName].apiEndpoint,
          modelName,
          body: updatedFormState,
          invalidatesTags: fieldsMetadata?.invalidatesTags,
        }).unwrap();
      }
    } catch (error) {
      showToastErrorMessage(`There was an error trying to create row table`);
    } finally {
      setIsLoadingForm(false);
      closeModal();
    }
  };

  const buttonSubmit = !isEditMode ? 'Create' : 'Edit';

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="max-h-[calc(100vh-64px*2)] overflow-y-scroll w-full max-w-[80vw]"
      childrenClassName="p-0"
    >
      <ContainerModal>
        <HeaderModal>
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            {`${buttonSubmit} Table Row`}
          </Typography>
        </HeaderModal>

        <BodyModal>
          <form
            id={CREATE_ENTITY_FORM}
            className="flex flex-col justify-between gap-y-6 min-h-[368px] min-w-[451px] w-full"
            onSubmit={handleSubmit}
          >
            <FormFields
              schema={schema}
              formState={formState}
              idField={idField}
              isLoadingForm={isLoadingForm}
              onChange={handleChange}
              relationOptions={relationOptions}
              fieldsMetadata={fieldsMetadata}
              selectedRow={selectedRow}
            />
          </form>
        </BodyModal>

        <FooterModal>
          <Button
            type="button"
            variant="outlined"
            color="basic"
            size="lg"
            onClick={closeModal}
            disabled={isLoadingForm}
          >
            Cancel
          </Button>

          <Button
            form={CREATE_ENTITY_FORM}
            type="submit"
            color="success"
            size="lg"
            autoFocus
            disabled={isLoadingForm}
          >
            {isLoadingForm ? <Spinner size="xs" /> : buttonSubmit}
          </Button>
        </FooterModal>
      </ContainerModal>
    </Modal>
  );
};

export { CreateOrUpdateTableRowModal };
