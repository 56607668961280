import { Account } from './account';
import { Edit } from './edit';
import { Notifications } from './notifications';
import { Misc } from './misc';
import { Chevrons } from './chevrons';
import { Controls } from './controls';
import { Business } from './business';

export const Outlined = {
  Account,
  Edit,
  Notifications,
  Misc,
  Chevrons,
  Controls,
  Business,
};
