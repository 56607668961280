import { DateRangeType } from 'src/shared/ui/datepicker/types';
import dayjs from 'dayjs';
import { MetadataMap } from 'src/shared/types';
import { isArrayMultiselectOptions } from 'src/shared/utils';

const prepareDateValue = (value: any, key: string, fieldsMetadata: MetadataMap) => {
  if (value && typeof value === 'object' && 'startDate' in value) {
    const dateValue = (value as DateRangeType).startDate;

    if (!dateValue || fieldsMetadata?.update?.[key]?.hidden) {
      return [key, undefined];
    }

    const date = dayjs(dateValue).toISOString();
    return [key, date];
  }

  return null;
};

const prepareMultiselectValue = (value: any, key: string) => {
  if (isArrayMultiselectOptions(value)) {
    const preparedMultiselectValues =
      value.length > 0
        ? value.map((el) => (typeof el === 'object' && 'value' in el ? el.value : el))
        : [];

    return [key, preparedMultiselectValues];
  }
  return null;
};

const prepareOptionValue = (
  value: any,
  key: string,
  previousFormState: Record<string, any> | null,
) => {
  if (value && typeof value === 'object' && 'value' in value) {
    const optionValue = value.value;

    const previousValue = previousFormState ? previousFormState[key] : null;

    if (optionValue === previousValue) {
      return [key, undefined];
    }

    return [key, optionValue];
  }
  return null;
};

const areArraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

const checkForChanges = (
  newFormState: Record<string, any>,
  previousFormState: Record<string, any> | null,
) => {
  if (previousFormState === null) {
    return newFormState;
  }

  const updatedFormState = Object.fromEntries(
    Object.entries(newFormState).map(([key, newValue]) => {
      const previousValue = previousFormState[key];

      if (Array.isArray(newValue) && Array.isArray(previousValue)) {
        return [key, areArraysEqual(newValue, previousValue) ? undefined : newValue];
      }

      return [key, newValue === previousValue ? undefined : newValue];
    }),
  );

  return updatedFormState;
};

export { prepareDateValue, prepareMultiselectValue, prepareOptionValue, checkForChanges };
