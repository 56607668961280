import { FC, useMemo, useState } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { Checkboxes } from 'src/shared/ui/checkboxes';
import { CheckboxItem } from 'src/shared/ui/checkboxes/checkboxes';
import { TableReportByActivities, TableReportByActivityStates } from './ui';
import { ActivityEntity, EntityRecord } from 'src/shared/types';

const ActivityReports: FC = () => {
  const [totalValues, setTotalValues] = useState({
    total: 0,
    totalStates: 0,
  });

  const handleSetTotal = (value: number, statesValue: number) => {
    setTotalValues({
      total: value,
      totalStates: statesValue,
    });
  };

  const [selectedEntity, setSelectedEntity] = useState<ActivityEntity>(ActivityEntity.Activities);

  const isTableByStates = useMemo(() => selectedEntity === ActivityEntity.States, [selectedEntity]);

  const entities: EntityRecord[] = [
    {
      label: ActivityEntity.Activities,
      amount: totalValues.total,
    },
    {
      label: ActivityEntity.States,
      amount: totalValues.totalStates,
    },
  ];

  const handleSelectEntity = (option: EntityRecord) => {
    setSelectedEntity(option.label);
  };

  return (
    <div className="w-full flex-col px-6">
      <Typography variant="h2">Activity Reports</Typography>

      <div className="flex justify-end mb-4">
        <Checkboxes
          options={entities.reduce<CheckboxItem[]>((acc, option) => {
            return [
              ...acc,
              {
                className: 'w-[151px]',
                label: option.label,
                amount: option.amount,
                onClick: () => handleSelectEntity(option),
              },
            ];
          }, [])}
          selectedValue={selectedEntity}
          className="w-fit"
        />
      </div>

      {isTableByStates ? (
        <TableReportByActivityStates
          isSkip={!isTableByStates}
          onTotalChange={handleSetTotal}
        />
      ) : (
        <TableReportByActivities
          isSkip={isTableByStates}
          onTotalChange={handleSetTotal}
        />
      )}
    </div>
  );
};

export { ActivityReports };
