import clsx from 'clsx';
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';

import {
  ActivityHealth,
  ActivityJob,
  ActivityTrack,
  ActivityUpdated,
  WorkTrack,
} from 'src/shared/types';
import { Icons } from 'src/assets/icons';
import { Typography } from 'src/shared/ui/typography';
import { RootState } from 'src/store';

import {
  CARD_SPREAD_BUTTON_RADIUS,
  CARD_UPDATE_ICON_SIZE,
  getMinWidth,
  MAX_CARD_Z_INDEX,
  ONE_HOUR_DAY_WIDTH_BY_MINUTES,
  ONE_HOUR_WIDTH_BY_MINUTES,
} from '../../helpers';
import { ActivityProgress } from '../activityProgress';
import { ActivityBadge } from '../activityBadge';
import { TaskManagementInfo } from '../taskManagementInfo';
import { TaskTimeInfo } from '../taskTimeInfo';
import { ActivityJobUpdatesTooltip } from '../activityJobUpdatesTooltip';
import { CardColorIndicator } from './CardColorIndicator';

interface CardContainerProps {
  hasMoreSpace: boolean;
  setMinCardWidthByContent: (width: number) => void;
  card: ActivityJob;
  cardMarginLeft: string;
  zIndex: number;
  cardWidth: number;
  minCardWidth: number;
  timeZone: string;
  isWideCard: boolean;
  handleSpreadCard: () => void;
  setIsAddUpdateModalOpen: () => void;
  setIsUpdatesListOpen: () => void;
  isAbsolute?: boolean;
  isOverlapped?: boolean;
  indexOverLapped?: number;
  startTime: Date;
}

const CardContainer: FC<CardContainerProps> = ({
  hasMoreSpace,
  setMinCardWidthByContent,
  card,
  cardMarginLeft,
  zIndex,
  cardWidth,
  minCardWidth,
  timeZone,
  isWideCard,
  handleSpreadCard,
  setIsAddUpdateModalOpen,
  setIsUpdatesListOpen,
  isAbsolute,
  isOverlapped,
  indexOverLapped,
  startTime,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);
  const isDayPeriod = useMemo(() => filterPeriod === 'day', [filterPeriod]);

  const updatesOfState = useMemo(() => {
    if (!card.updates) {
      return [];
    }

    const updatesBetweenTime = card.updates
      .slice(0, -1)
      .filter(
        (update) =>
          dayjs(update.createdAt).isAfter(dayjs(startTime)) &&
          dayjs(update.createdAt).isBetween(
            dayjs(startTime),
            dayjs(card.actualEnd || card.endWork),
          ),
      );

    return [...updatesBetweenTime].sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)));
  }, [card]);

  useEffect(() => {
    if (cardRef.current) {
      const minWidth = getMinWidth(cardRef.current);
      setMinCardWidthByContent(minWidth);
    }
  }, [card.updates]);

  const [showUpdatesIndex, setShowUpdatesIndex] = useState<number | null>(null);

  const isTooltipOpen = typeof showUpdatesIndex === 'number';

  const handleWorkTypeSelect = (
    status: ActivityHealth | ActivityTrack | ActivityUpdated,
    index?: number,
  ) => {
    const isIndex = typeof index === 'number';

    if (status === WorkTrack.Updated && !isIndex) {
      setIsUpdatesListOpen();
    }
    if (status === WorkTrack.Updated && isIndex) {
      setShowUpdatesIndex(index);
    }
  };

  const hideUpdates = () => {
    setShowUpdatesIndex(null);
  };

  const cardZIndex = useMemo(() => {
    const correctWideCardZIndex = isWideCard ? 1 : 0;
    const correctIndexOverLapped = typeof indexOverLapped === 'number' ? 1 + indexOverLapped : 0;

    return isTooltipOpen
      ? MAX_CARD_Z_INDEX + 100
      : MAX_CARD_Z_INDEX - zIndex + correctWideCardZIndex - correctIndexOverLapped;
  }, [zIndex, isWideCard, indexOverLapped, isTooltipOpen]);

  const updatesCount = (card.updates?.length || 1) - 1;

  return (
    <div
      className={clsx(
        'flex h-[167px] shrink-0 transition-all duration-300 w-max',
        hasMoreSpace && 'relative pr-4',
      )}
      style={{
        marginLeft: isAbsolute ? '0px' : cardMarginLeft,
        zIndex: cardZIndex,
      }}
    >
      <div
        className={clsx('flex h-auto shrink-0 overflow-x-hidden transition-all duration-300')}
        style={{
          width: `${isAbsolute ? minCardWidth : cardWidth}px`,
        }}
        data-tooltip-id={`updates-${card.id}`}
        ref={isWideCard ? undefined : cardRef}
      >
        <CardColorIndicator card={card} />
        <div
          className={clsx(
            'p-2 flex flex-1 flex-col gap-3 rounded-r-lg',
            isOverlapped ? 'bg-red-50' : 'bg-white',
          )}
        >
          <div className="flex items-center justify-between gap-2 shrink-0">
            <div className={clsx('shrink-0', isOverlapped && 'flex items-center gap-2')}>
              {isOverlapped && (
                <>
                  <Icons.Outlined.Business.OverbookedIcon
                    className="w-6 h-6 fill-[#ff0000]"
                    data-tooltip-id={`overbooked-${card.id}`}
                  />

                  <Tooltip
                    id={`overbooked-${card.id}`}
                    place="bottom-start"
                    content="This activity has a conflict for the assigned team"
                    className="max-w-[250px] !z-[9999] bg-black bg-opacity-70"
                  />
                </>
              )}

              <Typography
                variant="p2"
                fontWeight="bold"
                className="shrink-0"
              >
                {card.jobNumber}
              </Typography>
            </div>

            <div className="flex gap-2 shrink-0">
              <ActivityProgress progress={card.workProgress} />

              <ActivityBadge
                onValueSelect={handleWorkTypeSelect}
                status={card.workTrack}
              />

              <ActivityBadge
                onValueSelect={handleWorkTypeSelect}
                status={WorkTrack.Updated}
                updatesCount={updatesCount}
              />
            </div>
          </div>

          <TaskManagementInfo
            activityName={card.activityName}
            provider={card.providerName}
            crew={card.providerTeam.name}
          />

          <TaskTimeInfo
            endWork={card.actualEnd || card.endWork}
            startWork={card.actualStart || card.startWork}
            timeZone={timeZone}
          />

          {updatesOfState && (
            <div className="flex items-center shrink-0">
              {updatesOfState.map((update, index) => {
                const distanceValue = isDayPeriod
                  ? ONE_HOUR_DAY_WIDTH_BY_MINUTES
                  : ONE_HOUR_WIDTH_BY_MINUTES;

                const leftMinutesDistance =
                  index > 0
                    ? dayjs(update.createdAt).diff(
                        dayjs(updatesOfState[index - 1].createdAt),
                        'minute',
                      ) * distanceValue
                    : dayjs(update.createdAt).diff(dayjs(startTime), 'minute') * distanceValue;

                return (
                  <Fragment key={update.id}>
                    <div
                      className={clsx(
                        'h-px -mx-0.5',
                        index === 0 ? 'bg-transparent' : 'bg-semanticColor-warning',
                      )}
                      style={{
                        width: `${leftMinutesDistance - CARD_UPDATE_ICON_SIZE}px`,
                      }}
                    />

                    <button
                      type="button"
                      onClick={() => handleWorkTypeSelect(WorkTrack.Updated, index)}
                    >
                      <Icons.Outlined.Notifications.AlertCircleIcon
                        style={{
                          width: `${CARD_UPDATE_ICON_SIZE}px`,
                          height: `${CARD_UPDATE_ICON_SIZE}px`,
                        }}
                        className="fill-semanticColor-warning"
                      />
                    </button>
                  </Fragment>
                );
              })}
            </div>
          )}

          <div className="flex gap-2 items-center">
            <button
              type="button"
              onClick={setIsAddUpdateModalOpen}
            >
              <Typography
                variant="p2"
                fontWeight="bold"
                className="text-brandingColor-primary-gradient"
              >
                Add Update
              </Typography>
            </button>
          </div>
        </div>

        {isTooltipOpen && (
          <ActivityJobUpdatesTooltip
            isOpen={isTooltipOpen}
            indexOfUpdate={showUpdatesIndex || 0}
            card={card}
            hideUpdates={hideUpdates}
            timeZone={timeZone}
            currentUpdate={updatesOfState[showUpdatesIndex || 0]}
          />
        )}

        {hasMoreSpace && (
          <button
            type="button"
            className={clsx(
              'flex items-center justify-center p-1 bg-brandingColor-primary-gradient rounded-full border border-textColor-light absolute top-1/2 right-0 transform -translate-y-1/2 transition-all duration-300',
              isWideCard && 'rotate-180',
            )}
            style={{
              width: `${CARD_SPREAD_BUTTON_RADIUS * 2}px`,
              height: `${CARD_SPREAD_BUTTON_RADIUS * 2}px`,
            }}
            onClick={handleSpreadCard}
          >
            <Icons.Outlined.Chevrons.ArrowLeftIcon className="fill-white" />
          </button>
        )}
      </div>
    </div>
  );
};

export { CardContainer };
