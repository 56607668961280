import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useSelector } from 'react-redux';

import { Button } from 'src/shared/ui/button';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { dayjs, not, showToastErrorMessage } from 'src/shared/utils';
import { Icons } from 'src/assets/icons';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { TextField } from 'src/shared/ui/textField';
import {
  useGetActivityProvidersQuery,
  useGetEquipmentTypesQuery,
  useGetFreeByDatesProviderTeamsQuery,
  usePostNewActivityByProviderTeamMutation,
} from 'src/store/api/activities';
import { selectCurrentUser } from 'src/store/slices';

import { addJobSchema, DEFAULT_SELECT_VALUE } from '../../helpers';
import { TextAreaInput } from '../textAreaInput';
import { DueDateRangePicker } from '../dueDateRangePicker';
import { EnumActivityTrack } from 'src/shared/types';

interface AddActivityJobModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  timeZone: string;
  ownerLocationID: string;
}

const AddActivityJobModal: FC<AddActivityJobModalProps> = ({
  isOpen,
  setIsOpen,
  timeZone,
  ownerLocationID,
}) => {
  const toggleModal = useCallback(() => {
    setIsOpen((prev) => not(prev));
  }, [setIsOpen]);

  const { data: providerValues } = useGetActivityProvidersQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const { data: equipmentTypeValues } = useGetEquipmentTypesQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const providerVariants = useMemo(() => {
    return (
      providerValues?.map((provider) => ({
        id: provider.ProviderID,
        name: provider.ProviderName,
      })) || [DEFAULT_SELECT_VALUE]
    );
  }, [providerValues]);

  const providerEquipmentTypeVariants = useMemo(() => {
    return (
      equipmentTypeValues?.map((resp) => ({
        name: resp.EquipmentType,
        id: resp.EquipmentTypeID,
      })) || [DEFAULT_SELECT_VALUE]
    );
  }, [equipmentTypeValues]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const [createNewActivity] = usePostNewActivityByProviderTeamMutation();

  const user = useSelector(selectCurrentUser);

  const onSubmit = async (data: z.infer<typeof addJobSchema>) => {
    if (!user) return;

    try {
      const resp = await createNewActivity({
        teamId: data.providerTeam.id,
        ownerLocationID,
        reportedBy: user.email,
        jobNumber: data.jobNumber,
        equipment: data.equipment,
        equipmentTypeId: data.type.id,
        progress: 0,
        providerId: data.provider.id,
        health: 'OnTrack',
        track: EnumActivityTrack.NotStarted,
        providerTeamId: data.providerTeam.id,
        startDate: dayjs.utc(data.startTime).toISOString(),
        endDate: dayjs.utc(data.endTime).toISOString(),
        notes: data.notes || undefined,
        name: data.activityName,
        jobName: data.jobName,
      }).unwrap();
      if (typeof resp === 'object') {
        closeModal();
      } else if (typeof resp === 'string') {
        showToastErrorMessage(resp);
      }
    } catch (error) {
      showToastErrorMessage('There was an error trying to create a job');
    }
  };

  const initialValues = useMemo(
    () => ({
      activityName: '',
      jobName: '',
      jobNumber: '',
      equipment: '',
      type: DEFAULT_SELECT_VALUE,
      startTime: dayjs.utc().tz(timeZone).toISOString(),
      duration: '',
      endTime: dayjs.utc().tz(timeZone).toISOString(),
      providerTeam: DEFAULT_SELECT_VALUE,
      provider: DEFAULT_SELECT_VALUE,
      notes: '',
    }),
    [timeZone],
  );

  const { values, handleChange, handleSubmit, setFieldValue, errors } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(addJobSchema),
    initialValues,
    enableReinitialize: true,
  });

  const { data: providerTeamValues } = useGetFreeByDatesProviderTeamsQuery(
    {
      id: values.provider.id,
      startDate: dayjs
        .utc(values.startTime || dayjs())
        .tz(timeZone)
        .toISOString(),
      endDate: dayjs
        .utc(values.endTime || dayjs())
        .tz(timeZone)
        .toISOString(),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !values.provider.id || !values.startTime || !values.endTime,
    },
  );

  const providerTeamVariants = useMemo(() => {
    return providerTeamValues || [DEFAULT_SELECT_VALUE];
  }, [providerTeamValues]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[calc(100%-30px)] md:w-[646px] overflow-y-auto"
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        Add Discovery
      </Typography>

      <form
        className="w-full"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col justify-between gap-y-6 w-full max-h-[60vh] lg:max-h-[50vh] min-h-[368px] md:h-[616px] overflow-y-auto p-1">
          <TextField
            name="activityName"
            label="Activity Name"
            placeholder="Activity Name..."
            required
            isRequired
            value={values.activityName}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'activityName',
                  value: value.target.value,
                },
              })
            }
            error={errors.activityName}
          />

          <TextField
            name="jobNumber"
            label="Job Id"
            placeholder="Job Id..."
            required
            isRequired
            value={values.jobNumber}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'jobNumber',
                  value: value.target.value,
                },
              })
            }
            error={errors.jobNumber}
          />

          <TextField
            name="jobName"
            label="Job Name"
            placeholder="Job Name..."
            required
            isRequired
            value={values.jobName}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'jobName',
                  value: value.target.value,
                },
              })
            }
            error={errors.jobName}
          />

          <div className="flex gap-x-8 w-full">
            <TextField
              name="equipment"
              label="Equipment"
              value={values.equipment}
              placeholder="Equipment..."
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'equipment',
                    value: value.target.value,
                  },
                })
              }
              error={errors.equipment}
            />

            <SelectInput
              isRequired
              required
              name="type"
              label="Type"
              value={values.type.name}
              placeholder="Type..."
              onClear={() => setFieldValue('type', DEFAULT_SELECT_VALUE)}
              items={
                providerEquipmentTypeVariants.map(({ id, name }) => ({
                  label: <SelectInputItem selected={values.type.id === id}>{name}</SelectInputItem>,
                  value: id,
                  onClick: () => {
                    handleChange({
                      target: {
                        name: 'type',
                        value: {
                          id,
                          name,
                        },
                      },
                    });
                  },
                })) ?? []
              }
            />
          </div>
          <DueDateRangePicker
            values={values}
            timeZone={timeZone}
            setFieldValue={setFieldValue}
            errors={errors}
            ampm={false}
          />
          <SelectInput
            isRequired
            required
            name="provider"
            label="Assign Provider"
            value={values.provider.name}
            placeholder="Assign provider ..."
            disabled={!providerVariants}
            onClear={() => setFieldValue('provider', DEFAULT_SELECT_VALUE)}
            items={
              providerVariants.map(({ id, name }) => ({
                label: (
                  <SelectInputItem selected={values.provider.name === name}>{name}</SelectInputItem>
                ),
                value: id,
                onClick: () => {
                  handleChange({
                    target: {
                      name: 'provider',
                      value: {
                        id,
                        name,
                      },
                    },
                  });
                },
              })) ?? []
            }
          />

          <SelectInput
            isRequired
            required
            name="providerTeam"
            label="Assign Provider Team"
            value={values.providerTeam.name}
            placeholder="Assign provider team ..."
            disabled={!providerTeamValues || !values.provider.id}
            onClear={() => setFieldValue('providerTeam', DEFAULT_SELECT_VALUE)}
            items={
              providerTeamVariants.map(({ id, name }) => ({
                label: (
                  <SelectInputItem selected={values.providerTeam.name === name}>
                    {name}
                  </SelectInputItem>
                ),
                value: id,
                onClick: () => {
                  handleChange({
                    target: {
                      name: 'providerTeam',
                      value: {
                        id,
                        name,
                      },
                    },
                  });
                },
              })) ?? []
            }
          />

          <TextAreaInput
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'notes',
                  value,
                },
              })
            }
            value={values.notes}
            label="Notes"
            placeholder="Add notes"
          />
        </div>

        <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
          <Button
            type="button"
            variant="outlined"
            color="basic"
            size="lg"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            size="lg"
            endIcon={<Icons.Outlined.Edit.CheckmarkIcon className="fill-white" />}
            autoFocus
          >
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { AddActivityJobModal };
