import { FC } from 'react';
import clsx from 'clsx';

import { TypeNotation, RelationOptionsMap, MetadataMap } from 'src/shared/types';

import { RowField } from '../RowField';

interface IRowFieldsProps {
  selectedRow: Record<string, unknown> | null;
  schema: TypeNotation[];
  relationOptions: RelationOptionsMap;
  fieldsMetadata: MetadataMap;
}

const RowFields: FC<IRowFieldsProps> = ({
  selectedRow,
  schema,
  relationOptions,
  fieldsMetadata,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {selectedRow &&
        schema.map((schemaElement) => {
          if (fieldsMetadata?.details && fieldsMetadata?.details?.[schemaElement.field]?.hidden) {
            return null;
          }
          return (
            <div
              key={schemaElement.field}
              className={clsx(schemaElement.type === 'array' && 'col-span-2')}
            >
              <RowField
                selectedRow={selectedRow}
                schemaElement={schemaElement}
                relationOptions={relationOptions}
                fieldsMetadata={fieldsMetadata}
              />
            </div>
          );
        })}
    </div>
  );
};

export { RowFields };
