import { FC, useState } from 'react';
import clsx from 'clsx';

import { Typography } from 'src/shared/ui/typography';
import { TextField } from 'src/shared/ui/textField';
import { Checkbox } from 'src/shared/ui/checkbox';
import { Datepicker } from 'src/shared/ui/datepicker';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { DateValueType } from 'src/shared/ui/datepicker/types';
import {
  TableManagerFieldValue,
  TypeNotation,
  RelationOptionsMap,
  MetadataMap,
  RelationOption,
} from 'src/shared/types';
import { IconButton } from 'src/shared/ui/iconButton';
import { Icons } from 'src/assets/icons';
import { DropDown } from 'src/shared/ui/dropDown';
import { DropDownItemMenu } from 'src/shared/ui/dropDown/ui/dropDownItemMenu';
import { MultiSelectInput } from 'src/shared/ui/MultiSelectInput';
import { isMultiselectOption, isStringArray } from 'src/shared/utils';

interface FormFieldProps {
  schemaElement: TypeNotation;
  value: TableManagerFieldValue;
  isLoadingForm: boolean;
  relationOptions: RelationOptionsMap;
  fieldsMetadata: MetadataMap;
  onChange: (field: string, value: TableManagerFieldValue) => void;
}

const emptyOption = {
  value: '',
  label: '',
};

const FormField: FC<FormFieldProps> = ({
  schemaElement: { field, type, values = [], isOptional },
  value,
  isLoadingForm,
  relationOptions,
  fieldsMetadata,
  onChange,
}) => {
  const [multiTextField, setMultiTextField] = useState<string>('');

  const handleAddItem = (list: string[]) => {
    if (
      multiTextField.trim() !== '' &&
      Array.isArray(list) &&
      list.every((item) => typeof item === 'string') &&
      !list.includes(multiTextField.trim())
    ) {
      onChange(field, [...list, multiTextField]);
      setMultiTextField('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      handleAddItem(value as string[]);
    }
  };

  const handleCustomSelectInputValue = (value: string) => {
    onChange(field, {
      label: value,
      value,
    });
  };

  const label = fieldsMetadata?.headerNames?.[field]?.name ?? field;

  if (
    field in relationOptions &&
    fieldsMetadata?.multiSelectFields?.includes(field) &&
    Array.isArray(value)
  ) {
    const multiSelectValue: RelationOption[] = [...value] as RelationOption[];

    return (
      <MultiSelectInput
        isRequired={!isOptional}
        required={!isOptional}
        placeholder={`Select ${field}`}
        label={label}
        value={(value as RelationOption[]).map(({ value }) => value)}
        items={
          relationOptions[field].map((option) => {
            return {
              label: (
                <SelectInputItem
                  selected={multiSelectValue.some((item) => {
                    return item.value === option.value;
                  })}
                >
                  {option.label}
                </SelectInputItem>
              ),
              identifier: option.value,
              value: option.label,
              onClick: () => {
                if (Array.isArray(value)) {
                  const isAlreadySelected = value?.some(
                    (item) => isMultiselectOption(item) && item.value === option.value,
                  );

                  if (isAlreadySelected) {
                    const filteredValue = multiSelectValue?.filter(
                      (item) => isMultiselectOption(item) && item.value !== option.value,
                    );

                    onChange(field, filteredValue);
                  } else {
                    onChange(field, [...multiSelectValue, option]);
                  }
                }
              },
            };
          }) ?? []
        }
      />
    );
  }

  if (field in relationOptions) {
    const selectedValue =
      typeof value === 'object' && value !== null && 'label' in value ? value.label : '';

    return (
      <SelectInput
        isRequired={!isOptional}
        required={!isOptional}
        label={label}
        value={selectedValue}
        placeholder={`Select ${label}`}
        onCustomValue={handleCustomSelectInputValue}
        clearFieldState={() => onChange(field, emptyOption)}
        items={
          relationOptions[field].map((option) => ({
            label: (
              <SelectInputItem selected={selectedValue === option.value}>
                {option.label}
              </SelectInputItem>
            ),
            value: option.label,
            onClick: () => {
              onChange(field, option);
            },
          })) ?? []
        }
      />
    );
  }

  if (type === 'enum') {
    return (
      <SelectInput
        isRequired={!isOptional}
        required={!isOptional}
        label={label}
        value={value as string}
        placeholder={`Select ${label}`}
        onClear={() => onChange(field, '')}
        items={
          values.map((option) => ({
            label: <SelectInputItem selected={value === option}>{option}</SelectInputItem>,
            value: option,
            onClick: () => {
              onChange(field, option);
            },
          })) ?? []
        }
      />
    );
  }

  if (type === 'string') {
    return (
      <TextField
        type={type}
        name={field}
        label={label}
        value={value as string}
        onChange={(e) => onChange(field, e.target.value)}
        required={!isOptional}
        isRequired={!isOptional}
        disabled={isLoadingForm}
      />
    );
  }

  if (type === 'number') {
    return (
      <TextField
        type={type}
        name={field}
        label={label}
        value={value as string}
        onChange={(e) => onChange(field, e.target.value)}
        required={!isOptional}
        isRequired={!isOptional}
        disabled={isLoadingForm}
        step=".01"
        min={0}
      />
    );
  }

  if (type === 'boolean') {
    return (
      <div className={clsx('flex flex-col gap-y-2 w-full')}>
        <Typography variant="label">{label}</Typography>

        <Checkbox
          endLabel={`Select ${field}`}
          name={field}
          checked={value as boolean}
          onChange={(e) => onChange(field, e.target.checked)}
          disabled={isLoadingForm}
        />
      </div>
    );
  }

  if (type === 'date') {
    return (
      <div className={clsx('flex flex-col gap-y-2 w-full')}>
        <Typography variant="label">
          {label}

          {!isOptional && <span className="text-textColor-danger">*</span>}
        </Typography>

        <Datepicker
          value={value as DateValueType}
          onChange={(date) => onChange(field, date)}
          asSingle
          disabled={isLoadingForm}
          required={!isOptional}
        />
      </div>
    );
  }

  if (
    type === 'array' &&
    isStringArray(value) &&
    !fieldsMetadata?.multiSelectFields?.includes(field)
  ) {
    const stringsValue: string[] = [...value] as string[];

    return (
      <div className={clsx('flex flex-col gap-y-2 w-full')}>
        <TextField
          label={label}
          value={multiTextField}
          onChange={(e) => setMultiTextField(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          startIcon={
            <DropDown
              options={{ placement: 'bottom-start' }}
              config={{
                closeOnSelect: false,
                focusLastElement: false,
                itemsElementClassName: (isOpen) =>
                  clsx(
                    'shadow-[0px_2px_66px_-10px_#0000000F]',
                    'rounded-lg',
                    'bg-[#F3F6FA]',
                    'w-full',
                    'p-4',
                    !isOpen && 'p-0 border-0',
                  ),
              }}
              renderElement={(isOpen) => (
                <div className="absolute left-1 w-[40px] flex justify-center items-center">
                  <Icons.Outlined.Chevrons.ArrowUpIcon
                    className={clsx(
                      'fill-[#2E3A59] transition-all cursor-pointer',
                      isOpen ? 'rotate-0' : 'rotate-180',
                    )}
                  />
                </div>
              )}
              items={stringsValue.map((item) => ({
                value: item,
                label: (
                  <DropDownItemMenu
                    endIcon={
                      <IconButton
                        size="md"
                        onClick={() => {
                          const newList = stringsValue.filter((el) => el !== item);
                          onChange(field, newList);
                        }}
                      >
                        <Icons.Outlined.Edit.TrashIcon />
                      </IconButton>
                    }
                    titleClassName="text-textColor-tertiary"
                  >
                    {item}
                  </DropDownItemMenu>
                ),
              }))}
            />
          }
          endIcon={
            <div className="absolute right-1">
              {multiTextField.length > 0 ? (
                <IconButton
                  color="basic"
                  size="md"
                  iconSize="md"
                  iconClassName="fill-[#231F20]"
                  onClick={() => handleAddItem(stringsValue)}
                  disabled={!multiTextField.length}
                >
                  <Icons.Outlined.Edit.PlusIcon />
                </IconButton>
              ) : (
                <Typography
                  variant="h3"
                  className="flex items-center justify-center size-[40px] text-semanticColor-success"
                >
                  {value.length}
                </Typography>
              )}
            </div>
          }
        />
      </div>
    );
  }

  return null;
};

export { FormField };
