import { api } from 'src/store/api/api';
import { Inspector } from 'src/shared/types';

const inspectorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInspectors: builder.query<Inspector[], ''>({
      query: () => ({
        url: '/admin/inspectors/list',
        method: 'GET',
      }),
      providesTags: ['Inspectors'],
      transformResponse: (response: { data: Inspector[] }) => response.data,
    }),
  }),
});

export const { useGetInspectorsQuery } = inspectorApi;
