import { Dispatch, SetStateAction } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface CreateToolbarProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}

const CreateToolbar = (props: CreateToolbarProps) => {
  const { setIsOpen, disabled: isDisabled } = props;

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <Button
      disabled={isDisabled}
      color="primary"
      startIcon={<AddIcon />}
      onClick={handleClick}
    >
      Add record
    </Button>
  );
};

export { CreateToolbar };
